// 引入下载好的useCookies
import { useCookies } from '@vueuse/integrations/useCookies';
const cookie = useCookies();
// 获取属性
export function getProp(key) {
    return cookie.get(key);
}
// 设置属性
export function setProp(params) {
    return cookie.set(params.key, params.value);
}
// 清除属性
export function removeProp(key) {
    return cookie.remove(key);
}
