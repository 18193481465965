import axios from 'axios';
import { ElMessage } from 'element-plus';
import 'element-plus/dist/index.css';
import { getProp } from '@/utils/cookies';
// 创建请求实例
const instance = axios.create({
    baseURL: process.env.NODE_ENV === 'development' ? '/api' : '',
    // 指定请求超时的毫秒数
    // timeout: 1000,
    // 表示跨域请求时是否需要使用凭证
    // withCredentials: false,
});
// 前置拦截器（发起请求之前的拦截）
instance.interceptors.request.use((config) => {
    /**
     * 在这里一般会携带前台的参数发送给后台，比如下面这段代码：
     */
    const token = getProp('token');
    if (config.needToken) {
        if (token) {
            config.headers['Authorization'] = token;
        }
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});
/**
 * 根据你的项目实际情况来对 response 和 error 做处理
 * 这里对 response 和 error 不做任何处理，直接返回
 */
instance.interceptors.response.use((response) => {
    if (response.data.code !== 200) {
        ElMessage({
            message: response.data.msg,
            type: 'warning',
        });
    }
    return response.data;
}, (error) => {
    const { response } = error;
    if (response && response.data) {
        if (response.data.code) {
            if (response.data.code == 4001) {
                // router.push({
                //   name: 'login',
                //   query: {
                //     redirect: window.location.pathname,
                //   },
                // });
            }
            else {
                ElMessage({
                    message: response.data.msg,
                    type: 'warning',
                });
            }
        }
    }
    return Promise.reject(error);
});
export default instance;
