import instance from '@/api/request';
export const modelsGet = () => instance.request({
    url: `comfortuni/gugu/ai/models`,
    method: 'GET',
});
export const chatPost = (payload) => instance.request({
    url: `comfortuni/gugu/ai/chat`,
    method: 'POST',
    data: payload,
});
export const executeChatPost = (payload) => instance.request({
    url: `comfortuni/gugu/ai/execute-chat`,
    method: 'POST',
    data: payload,
});
