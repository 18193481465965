import { createApp } from 'vue';
import '@/style/base.css';
import '@/common/flexible.js';
// import 'virtual:svg-icons-register';
import '@imengyu/vue3-context-menu/lib/vue3-context-menu.css';
import ContextMenu from '@imengyu/vue3-context-menu';
import 'shepherd.js/dist/css/shepherd.css';
import App from '@/App.vue';
import store from '@/store';
import router from '@/router';
const app = createApp(App);
app.use(router).use(store).use(ContextMenu).mount('#app');
